/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://gist.github.com/lancejpollard/1978404
 *
 */
import { graphql, useStaticQuery } from 'gatsby'
import { useIntl } from 'gatsby-plugin-react-intl'
import { marked } from 'marked'
import React, { FC } from 'react'
import { Helmet } from 'react-helmet'

import { SiteSEOMetadata } from './__generated__/SiteSEOMetadata'

interface ISEOProps {
    description?: string
    meta?: any[]
    title: string
    isArticle?: boolean
    keywords?: string | null
    pageUrl?: string
    shareImage?: string | null
}

const SEO: FC<ISEOProps> = ({
    description = '',
    meta = [],
    title,
    isArticle = false,
    pageUrl,
    keywords,
    shareImage,
}) => {
    const intl = useIntl()

    const {
        site,
        strapiGlobal,
        allStrapiServiceCategory,
        allStrapiMinistries,
        allStrapiService,
        strapiAbout,
    } = useStaticQuery<SiteSEOMetadata>(
        graphql`
            query SiteSEOMetadata {
                site {
                    siteMetadata {
                        title
                        description
                        author
                        siteUrl
                    }
                }
                strapiAbout {
                    seo {
                        shareImage {
                            localFile {
                                publicURL
                            }
                        }
                        keyWords
                    }
                }
                strapiGlobal {
                    navMenus {
                        url
                        label_mg
                        label
                        id
                    }
                }
                allStrapiServiceCategory {
                    nodes {
                        id
                        categoryName
                        categorySlug
                    }
                }
                allStrapiMinistries {
                    nodes {
                        ministryName
                        ministrySlug
                        id
                    }
                }
                allStrapiService {
                    nodes {
                        id
                        serviceTitle
                        serviceSlug
                    }
                }
            }
        `,
    )

    const metaDescription = marked.parse((description || site?.siteMetadata?.description) ?? '')
    const defaultTitle = site?.siteMetadata?.title
    const siteUrl = site?.siteMetadata?.siteUrl ?? ''
    const currentUrl = siteUrl + (pageUrl ?? '')
    const shareImageURL =
        siteUrl + (shareImage ?? strapiAbout?.seo?.shareImage?.localFile?.publicURL ?? '')

    const breadcrumbList = {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [
            {
                '@type': 'ListItem',
                position: 1,
                item: {
                    '@id': siteUrl,
                    name: 'Torolalana',
                    image: undefined,
                },
            },
            // The main pages
            ...(strapiGlobal?.navMenus?.map((menuItem) => ({
                '@type': 'ListItem',
                position: 2,
                item: {
                    '@id': siteUrl + menuItem?.url,
                    name: menuItem?.label,
                    image: undefined,
                },
            })) ?? []),
            // category pages
            ...(allStrapiServiceCategory?.nodes?.map((categItem) => ({
                '@type': 'ListItem',
                position: 3,
                item: {
                    '@id': siteUrl + `/services/${categItem.categorySlug}`,
                    name: categItem?.categoryName,
                    image: undefined,
                },
            })) ?? []),
            // ministry pages
            ...(allStrapiMinistries?.nodes?.map((ministryItem) => ({
                '@type': 'ListItem',
                position: 3,
                item: {
                    '@id': siteUrl + `/ministeres/${ministryItem.ministrySlug}`,
                    name: ministryItem?.ministryName,
                    image: undefined,
                },
            })) ?? []),
            // service pages
            ...(allStrapiService?.nodes?.map((serviceItem) => ({
                '@type': 'ListItem',
                position: 4,
                item: {
                    '@id': siteUrl + `/services/${serviceItem.serviceSlug}`,
                    name: serviceItem?.serviceTitle,
                    image: undefined,
                },
            })) ?? []),
        ],
    }

    return (
        <Helmet titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : ''} defer={false}>
            {/* General tags */}
            <html lang={intl.locale} />
            <title> {title} </title>
            <meta name="description" content={metaDescription} />
            <meta http-equiv="Pragma" content="no-cache" />
            <meta http-equiv="Cache-Control" content="no-cache" />
            <meta name="keywords" content={keywords ?? strapiAbout?.seo?.keyWords ?? ''} />
            <meta name="image" content={shareImageURL} />

            {[
                /* OpenGraph tags */
                {
                    property: 'og:url',
                    content: currentUrl,
                },
                {
                    property: 'og:type',
                    content: isArticle ? 'article' : 'website',
                },
                {
                    property: 'og:title',
                    content: title,
                },
                {
                    property: 'og:description',
                    content: metaDescription,
                },
                {
                    property: 'og:image',
                    content: shareImageURL,
                },
                {
                    property: 'og:image:secure_url',
                    content: shareImageURL,
                },
                /* Twitter Card tags */
                {
                    name: 'twitter:card',
                    content: 'summary_large_image',
                    /*summary*/
                },
                {
                    name: 'twitter:creator',
                    content: site?.siteMetadata?.author || '',
                },
                {
                    name: 'twitter:title',
                    content: title,
                },
                {
                    name: 'twitter:site',
                    content: defaultTitle,
                },
                {
                    name: 'twitter:description',
                    content: metaDescription,
                },
                {
                    name: 'twitter:image',
                    content: shareImageURL,
                },
            ]
                .concat(meta)
                .map((metaItm, index) => (
                    <meta
                        key={index}
                        name={metaItm.name}
                        property={metaItm.property}
                        content={metaItm.content ?? ''}
                    />
                ))}
            <script type="application/ld+json">{JSON.stringify(breadcrumbList)}</script>
        </Helmet>
    )
}

export default SEO
